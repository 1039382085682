// Overriding Bootstrap's default variables
$primary: #347F63;
$light: #fffaf4;
$white: #fff;


// Import Bootstrap's source Sass files
@import "bootstrap/scss/bootstrap";


.btn:active {
    outline: none !important;
    box-shadow: none !important;
    background-color: $primary !important;
  }


.btn-outline-primary:hover {
    color: $light;
}

.btn-primary, .btn-primary:hover {
    color: $light;
}

.navbar-light .navbar-nav .nav-link  {
    color: $primary;//#262626;
}