
body {
    font-family: 'Arial', sans-serif;
}

footer {
    padding: 20px 0;
}

footer ul {
    list-style: none;
    padding: 0;
}

footer ul li {
    display: inline-block;
    margin-right: 20px;
}

a, a:hover {
    color: #525252;
    text-decoration: none;
}

.hidden {
    display: none;
}